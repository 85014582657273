import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Sidebar from "./Sidebar";
import AddClient from "./AddClient";
import EditClient from "./EditClient";
import DeleteClient from "./DeleteClient";

const Clients = ({ businessID }) => {
  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedClientID, setSelectedClientID] = useState(null);

  const fetchData = async () => {
    try {
      const clientsResponse = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Clients`
      );
      const clientsData = clientsResponse.data;

      setClients(clientsData);
      setTotalClients(clientsData.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [businessID]);

  const handleAddClient = () => {
    setOpenAddDialog(true);
  };

  const handleEditClient = (clientID) => {
    setSelectedClientID(clientID);
    setOpenEditDialog(true);
  };

  const handleDeleteClient = (clientID) => {
    setSelectedClientID(clientID);
    setOpenDeleteDialog(true);
  };

  const handleDialogClose = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    fetchData();
  };

  return (
    <Box sx={{ display: "flex", pr: "100px" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        <Container>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "black",
              fontFamily: "Jua",
              fontWeight: 900,
              textAlign: "left",
            }}
          >
            Clients
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "130px", fontFamily: "Jua" }}>
                <CardContent>
                  <Typography sx={{ fontFamily: "Jua" }} variant="h6">
                    Total Clients
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#25467B",
                      fontWeight: 400,
                      fontFamily: "Jua",
                    }}
                  >
                    {totalClients}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              color: "white",
              fontFamily: "Jua",
              fontWeight: 900,
              textAlign: "left",
            }}
            onClick={handleAddClient}
          >
            Add Client
          </Button>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "black",
              fontFamily: "Jua",
              fontWeight: 900,
              textAlign: "left",
            }}
          >
            All Clients
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 800, width: "60vw", bgcolor: "#2BAE9E" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "black",
                      fontFamily: "Jua",
                      fontWeight: 900,
                      textAlign: "left",
                    }}
                  >
                    Client ID
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "black",
                      fontFamily: "Jua",
                      fontWeight: 900,
                      textAlign: "left",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "black",
                      fontFamily: "Jua",
                      fontWeight: 900,
                      textAlign: "left",
                    }}
                  >
                    Surname
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "black",
                      fontFamily: "Jua",
                      fontWeight: 900,
                      textAlign: "left",
                    }}
                  >
                    Phone Number
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "black",
                      fontFamily: "Jua",
                      fontWeight: 900,
                      textAlign: "left",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "black",
                      fontFamily: "Jua",
                      fontWeight: 900,
                      textAlign: "left",
                    }}
                  >
                    Address
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "black",
                      fontFamily: "Jua",
                      fontWeight: 900,
                      textAlign: "left",
                    }}
                  >
                    Options
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((client) => (
                  <TableRow key={client.clientID}>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {client.clientID}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {client.name}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {client.surname}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {client.phoneNumber}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {client.email}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {client.address}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ fontFamily: "Jua" }}
                        onClick={() => handleEditClient(client.clientID)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteClient(client.clientID)}
                        sx={{
                          ml: 2,
                          backgroundColor: "#f44336",
                          width: "50%",
                          fontFamily: "Jua",
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
      <Dialog open={openAddDialog} onClose={handleDialogClose}>
        <DialogTitle>Add New Client</DialogTitle>
        <DialogContent>
          <AddClient
            businessID={businessID}
            onProductAdded={handleDialogClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleDialogClose}>
        <DialogTitle>Edit Client</DialogTitle>
        <DialogContent>
          <EditClient
            clientID={selectedClientID}
            onClientEdited={handleDialogClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleDialogClose}>
        <DialogContent>
          <DeleteClient
            clientID={selectedClientID}
            onClientDeleted={handleDialogClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Clients;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import { Hidden, Grid, Box, Typography } from '@mui/material';
import { PRIMARY_COLOR } from './assets/Colours';

const Dashboard = () => {
    const [remainingAppointments, setRemainingAppointments] = useState(0);
    const [timeUntilNextAppointment, setTimeUntilNextAppointment] = useState(null);
    const [nextAppointment, setNextAppointment] = useState(null);
    const [todayAppointments, setTodayAppointments] = useState([]);
    const [petData, setPetData] = useState({});
    const [petImages, setPetImages] = useState({});
    const [petNames, setPetNames] = useState({});

    useEffect(() => {
        fetchAppointments();
    }, []);

    const fetchAppointments = async () => {
        try {
            const response = await axios.get('https://clawsandpawsapi.azurewebsites.net/api/Appointments');
            const appointments = response.data;
            calculateRemainingAppointments(appointments);
            calculateTimeUntilNextAppointment(appointments);
            findNextAppointment(appointments);
            await fetchPetDetails(appointments);
            filterTodayAppointments(appointments);
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    const fetchPetDetails = async (appointments) => {
        const petIDs = [...new Set(appointments.map(appointment => appointment.petID))];
        const petDetails = {};

        await Promise.all(petIDs.map(async (petID) => {
            try {
                const response = await axios.get(`https://clawsandpawsapi.azurewebsites.net/api/Pets/${petID}`);
                const pet = response.data;
                petDetails[petID] = { name: pet.name, photo: pet.photo };
            } catch (error) {
                console.error('Error fetching pet data:', error);
            }
        }));

        setPetNames(petIDs.reduce((acc, petID) => {
            acc[petID] = petDetails[petID]?.name || 'Unknown';
            return acc;
        }, {}));

        setPetImages(petIDs.reduce((acc, petID) => {
            acc[petID] = petDetails[petID]?.photo || '';
            return acc;
        }, {}));
    };

    const filterTodayAppointments = (appointments) => {
      const now = new Date();
      const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const todayEnd = new Date(todayStart).setDate(todayStart.getDate() + 1);
  
      const filteredAppointments = appointments
          .filter(appointment => {
              const appointmentDate = new Date(appointment.appointmentDate);
              return appointmentDate >= todayStart && appointmentDate < todayEnd && appointmentDate > now && !appointment.isCancelled;
          })
          .sort((a, b) => new Date(a.appointmentDate) - new Date(b.appointmentDate)); // Sort by appointment date
  
      setTodayAppointments(filteredAppointments);
  };
  
  

  const calculateRemainingAppointments = (appointments) => {
    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const todayEnd = new Date(todayStart).setDate(todayStart.getDate() + 1);

    const filteredAppointments = appointments.filter(appointment => {
        const appointmentDate = new Date(appointment.appointmentDate);
        return appointmentDate >= todayStart && appointmentDate < todayEnd && appointmentDate > now && !appointment.isCancelled;
    });

    setRemainingAppointments(filteredAppointments.length);
};


    const calculateTimeUntilNextAppointment = (appointments) => {
        const now = new Date();
        const upcomingAppointments = appointments
            .filter(appointment => new Date(appointment.appointmentDate) > now && !appointment.isCancelled)
            .sort((a, b) => new Date(a.appointmentDate) - new Date(b.appointmentDate));

        if (upcomingAppointments.length > 0) {
            const nextAppointment = new Date(upcomingAppointments[0].appointmentDate);
            const diffInMilliseconds = nextAppointment - now;
            const diffInHours = Math.floor(diffInMilliseconds / 3600000);
            const diffInMinutes = Math.floor((diffInMilliseconds % 3600000) / 60000);
            setTimeUntilNextAppointment(`${diffInHours}h ${diffInMinutes}m`);
        } else {
            setTimeUntilNextAppointment(null);
        }
    };

    const findNextAppointment = async (appointments) => {
        const now = new Date();
        const upcomingAppointments = appointments
            .filter(appointment => new Date(appointment.appointmentDate) > now && !appointment.isCancelled)
            .sort((a, b) => new Date(a.appointmentDate) - new Date(b.appointmentDate));

        if (upcomingAppointments.length > 0) {
            const nextAppointment = upcomingAppointments[0];
            setNextAppointment(nextAppointment);
            const petID = nextAppointment.petID;
            const petData = await fetchPetData(petID);
            setPetData(petData);
        } else {
            setNextAppointment(null);
            setPetData(null);
        }
    };

    const fetchPetData = async (petID) => {
        try {
            const response = await axios.get(`https://clawsandpawsapi.azurewebsites.net/api/Pets/${petID}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching pet data:', error);
            return null;
        }
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 2, display: 'flex', fontFamily: 'Jua, sans-serif' }}>
            <Hidden mdDown>
                <Sidebar />
            </Hidden>
            <Box sx={{ flexGrow: 1, marginLeft: { md: '0px' }, padding: 2 }}>
                <Typography variant="h4" sx={{ fontFamily: 'Jua, sans-serif', position: 'absolute', top: '40px', left: '350px' }}>
                    Dashboard
                </Typography>

                <Grid container spacing={2} sx={{ marginTop: 8 }}>
                    <Grid item xs={12} md={5.93}>
                        <Box sx={{
                            padding: 2,
                            backgroundColor: PRIMARY_COLOR,
                            borderRadius: 2,
                            minHeight: '200px',
                            maxHeight: '400px', // Set a max height if needed
                            overflowY: 'auto', // Add scrolling for overflow
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                            <Typography variant="h6" sx={{ fontFamily: 'Jua, sans-serif' }}>
                                Upcoming Appointment
                            </Typography>
                            {nextAppointment ? (
                                <Box sx={{ display: 'flex', marginTop: 1 }}>
                                    {petData && petData.photo && (
                                        <Box sx={{ marginRight: 2, borderRadius: 30}}>
                                            <img
												src={`${process.env.PUBLIC_URL}/AnimalPhotos/${petData.photo}`}
                                                alt="Pet"
                                                style={{ width: '120px', height: '120px', borderRadius:50000 }}
                                            />
                                        </Box>
                                    )}
                                    <Box>
                                        <Typography variant="h5" sx={{ fontFamily: 'Jua, sans-serif', marginTop: 2.5 }}>
                                            {nextAppointment.groomingDetails} for {petData ? petData.name : 'Loading...'}
                                        </Typography>
                                        <Typography variant="h6" sx={{ marginTop: 1, fontFamily: 'Jua, sans-serif' }}>
                                            {petData ? petData.notes : 'No notes available'}
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Typography variant="h6" sx={{ marginTop: 1 }}>
                                    No upcoming appointments
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    
                    <Grid marginLeft={1}item xs={12} md={2}>
                        <Box sx={{
                            padding: 2,
                            backgroundColor: PRIMARY_COLOR,
                            borderRadius: 2,
                            minHeight: '200px',
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                            <Typography variant="h5" sx={{ marginBottom: 5, alignSelf: 'flex-start', fontFamily: 'Jua, sans-serif' }}>
                                Next Appointment
                            </Typography>
                            <Typography variant="h4" sx={{ marginBottom: 5, textAlign: 'center', fontFamily: 'Jua, sans-serif' }}>
                                {timeUntilNextAppointment !== null ? timeUntilNextAppointment : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid marginLeft={2} item xs={12} md={3}>
                        <Box sx={{
                            padding: 2,
                            backgroundColor: PRIMARY_COLOR,
                            borderRadius: 2,
                            minHeight: '200px',
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                            <Typography variant="h4" sx={{ marginBottom: 5, alignSelf: 'flex-start', fontFamily: 'Jua, sans-serif' }}>
                                Remaining
                            </Typography>
                            <Typography variant="h4" sx={{ marginBottom: 5, textAlign: 'center', fontFamily: 'Jua, sans-serif' }}>
                                {remainingAppointments}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8

                    }>
                        <Box sx={{
                            padding: 2,
                            backgroundColor: PRIMARY_COLOR,
                            borderRadius: 2,
                            minHeight: '500px',
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                                    maxHeight: 500,
        overflowY: 'auto'
                        }}>
                        <Typography variant="h5" sx={{ marginBottom: 10, alignSelf: 'flex-start', fontFamily: 'Jua, sans-serif' }}>
                                Calendar for Today
                            </Typography>
                            {todayAppointments.length > 0 ? (
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                                    gap: 2
                                }}>
                               {todayAppointments.map((appointment) => (
                                  <Box key={appointment.appointmentID} sx={{
                                  display: 'flex',
                                  flexDirection: 'column', // Stack vertically
                                  alignItems: 'center',    // Center align horizontally
                                  backgroundColor: 'white',
                                  padding: 3,
                                  borderRadius: 2,
                                  boxShadow: 1,
                                  marginBottom: 2,         // Add some space between items
                                 }}>
                                          <Box>
                                          <Typography variant="h6" sx={{ color: '#666',fontFamily: 'Jua, sans-serif'  }}>
                                                {new Date(appointment.appointmentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </Typography>
                                            </Box>
                                            {petImages[appointment.petID] ? (
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/AnimalPhotos/${petImages[appointment.petID]}`}
                                                    alt="Pet"
                                                    style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: 1 }}
                                                />
                                            ) : (
                                                <Box sx={{ width: '60px', height: '60px', marginRight: 1, backgroundColor: '#ddd', borderRadius: '8px' }}></Box>
                                            )}
                                            
                                        </Box>
                                    ))}
                                </Box>
                            ) : (
                                <Typography variant="h6">
                                    No appointments today
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid marginLeft={2}item xs={12} md={3

}>
    <Box sx={{
        padding: 1,
        backgroundColor: PRIMARY_COLOR,
        borderRadius: 2,
        minHeight: '100px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxHeight: 500,
        overflowY: 'auto'
    }}>
      
        {todayAppointments.length > 0 ? (
            <Box>
                <Typography variant="h5" sx={{ marginBottom: 2,marginLeft: 2,marginTop: 72 , alignSelf: 'flex-start', fontFamily: 'Jua, sans-serif' }}>
            Upcoming
        </Typography>
                {todayAppointments.map((appointment) => (
                    <Box key={appointment.appointmentID} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        {petImages[appointment.petID] ? (
                            <img
                                src={`${process.env.PUBLIC_URL}/AnimalPhotos/${petImages[appointment.petID]}`}
                                alt="Pet"
                                style={{ width: '60px', height: '60px', borderRadius: '40px', marginRight: 2 }}
                            />
                        ) : (
                            <Box sx={{ width: '60px', height: '60px', marginRight: 2, backgroundColor: '#ddd', borderRadius: '8px' }}></Box>
                        )}
                        <Box>
                            <Typography variant="h6" sx={{ fontFamily: 'Jua, sans-serif', marginLeft: 2}}>
                                {appointment.groomingDetails} for {petNames[appointment.petID] || 'Unknown'}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#666', marginLeft: 2  }}>
                            Time: {new Date(appointment.appointmentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            </Typography>
                        </Box>
                    </Box>
                ))}
                
            </Box>
        ) : (
            <Typography variant="h6">
                No appointments today
            </Typography>
        )}
    </Box>
</Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import Sidebar from "./Sidebar";
import { format } from "date-fns";
import { PRIMARY_COLOR } from "./assets/Colours";

const parseDate = (dateString) => {
  const parsedDate = new Date(dateString);
  if (!isNaN(parsedDate)) {
    return parsedDate;
  }
  console.error("Invalid date:", dateString);
  return null;
};

const StyledTableCell = ({ children }) => (
  <TableCell sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}>
    {children}
  </TableCell>
);

const StyledTableCellHeader = ({ children }) => (
  <TableCell
    sx={{
      fontFamily: "Jua !important",
      borderRight: "1px solid white !important",
      backgroundColor: `${PRIMARY_COLOR} !important`,
      color: "black !important",
      zIndex: 2,
    }}
  >
    {children}
  </TableCell>
);

const StyledButton = ({ children, ...props }) => (
  <Button
    {...props}
    variant="h4"
    sx={{
      "&:hover": {
        bgcolor: "2BAE9E",
        color: "#25467B",
        transition: "transform 0.3s",
        transform: "translateY(-5px)",
      },
      bgcolor: "#25467B",
      color: "white",
      fontFamily: "Jua",
      fontWeight: 900,
      textAlign: "center",
      width: "100%", // Ensure buttons take up full width of the container
    }}
  >
    {children}
  </Button>
);

const Appointment = ({ businessID }) => {
  const [appointments, setAppointments] = useState([]);
  const [clientFilter, setClientFilter] = useState("");
  const [petFilter, setPetFilter] = useState("");

  const fetchData = async () => {
    try {
      const appointmentsResponse = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Appointments`
      );
      setAppointments(appointmentsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredAppointments = appointments.filter(
    (appointment) =>
      (appointment.groomingDetails?.toLowerCase() || "").includes(
        clientFilter.toLowerCase()
      ) &&
      (appointment.groomingDetails?.toLowerCase() || "").includes(
        petFilter.toLowerCase()
      )
  );

  const today = new Date();
  const formattedDate = `${String(today.getDate()).padStart(2, "0")}/${String(
    today.getMonth() + 1
  ).padStart(2, "0")}/${today.getFullYear()}`;

  const tableHeaders = [
    "Time",
    "Client",
    "Pet",
    "Grooming Details",
    "Pickup?",
    "Drop off?",
  ];

  return (
    <Box sx={{ display: "flex", pr: "100px" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "black",
                fontFamily: "Jua",
                fontWeight: 900,
                textAlign: "left",
              }}
            >
              Appointments
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "black",
                fontFamily: "Jua",
                fontWeight: 900,
                textAlign: "right",
              }}
            >
              {formattedDate}
            </Typography>
          </Box>

          {/* Wrap the table and buttons inside a single Box with a set width */}
          <Box sx={{ width: "60vw" }}>
            <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header) => (
                      <StyledTableCellHeader key={header}>
                        {header}
                      </StyledTableCellHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAppointments.map((appointment, index) => (
                    <TableRow
                      key={appointment.appointmentID}
                      sx={{
                        bgcolor: index % 2 === 0 ? "#f5f5f5" : PRIMARY_COLOR,
                      }}
                    >
                      <StyledTableCell>
                        {format(
                          parseDate(appointment.appointmentDate),
                          "MMM dd, yyyy hh:mm a"
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{appointment.clientID}</StyledTableCell>
                      <StyledTableCell>{appointment.petID}</StyledTableCell>
                      <StyledTableCell>
                        {appointment.groomingDetails}
                      </StyledTableCell>
                      <StyledTableCell>
                        {appointment.isPickup ? "Yes" : "No"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {appointment.isDropOff ? "Yes" : "No"}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
                width: "100%",
                margin: "0 auto",
              }}
            >
              <StyledButton sx={{ width: "50px" }}>
                Add Appointment
              </StyledButton>
              <StyledButton sx={{ width: "50px" }}>
                Remove Appointment
              </StyledButton>
            </Box>
            */}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Appointment;

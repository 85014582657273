import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const AddClient = ({ onClientAdded }) => {
  const [clientData, setClientData] = useState({
    clientID: 0,
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    address: "",
  });

  useEffect(() => {});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    setClientData((prevData) => ({
      ...prevData,
      siteID: e.target.value,
    }));
  };

  const handleAddClient = async () => {
    try {
      await axios.post(
        "https://clawsandpawsapi.azurewebsites.net/api/Clients",
        clientData
      );
      onClientAdded();
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };

  return (
    <Box>
      <TextField
        label="Client ID"
        name="clientID"
        value={clientData.clientID}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Client Name"
        name="name"
        value={clientData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Surname"
        name="surname"
        value={clientData.surname}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Phone Number"
        name="phoneNumber"
        value={clientData.phoneNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={clientData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address"
        name="address"
        value={clientData.address}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddClient}
        sx={{
          mt: 2,
          color: "white",
          fontFamily: "Jua",
          fontWeight: 900,
          textAlign: "left",
        }}
      >
        Add Client
      </Button>
    </Box>
  );
};

export default AddClient;

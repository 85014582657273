import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Sidebar from "./Sidebar";
import { format, subDays, startOfMonth } from "date-fns";

const Staff = ({ businessID }) => {
  const [staff, setStaff] = useState([]);

  const fetchData = async () => {
    try {
      const staffResponse = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Users`
      );
      const staffData = staffResponse.data;

      console.log(staffData);

      setStaff(staffData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  });

  return (
    <Box sx={{ display: "flex", pr: "100px" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "black",
                fontFamily: "Jua",
                fontWeight: 900,
                textAlign: "left",
              }}
            >
              Staff
            </Typography>
          </Box>

          <TableContainer
            component={Paper}
            sx={{ maxHeight: 800, width: "60vw", bgcolor: "#2BAE9E" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                  >
                    Last Name
                  </TableCell>
                  <TableCell
                    sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                  >
                    Cell Number
                  </TableCell>
                  <TableCell
                    sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                  >
                    Active?
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staff.map((users) => (
                  <TableRow key={staff.userID}>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {users.firstName}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {users.lastName}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {users.phoneNumber}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {users.email}
                    </TableCell>
                    <TableCell
                      sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}
                    >
                      {users.isActive ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="h4"
              gutterBottom
              sx={{
                "&:hover": {
                  bgcolor: "2BAE9E",
                  color: "#25467B",
                  transition: "transform 0.3s",
                  transform: "translateY(-5px)",
                  "& .MuiListItemIcon-root": {
                    color: "#F1AC36",
                  },
                },
                bgcolor: "#25467B",
                color: "white",
                fontFamily: "Jua",
                fontWeight: 900,
                textAlign: "right",
              }}
            >
              Add Staff Member
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Staff;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button, Box } from "@mui/material";

const EditClient = ({ clientID, onClientEdited }) => {
  const [clientData, setClientData] = useState({
    clientID: clientID,
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    address: "",
  });

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await axios.get(
          `https://clawsandpawsapi.azurewebsites.net/api/Clients/${clientID}`
        );
        console.log(response);
        setClientData(response.data);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClient();
  }, [clientID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditClient = async () => {
    try {
      await axios.put(
        `https://clawsandpawsapi.azurewebsites.net/api/Clients/${clientID}`,
        clientData
      );
      onClientEdited();
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  return (
    <Box>
      <TextField
        label="Client Name"
        name="clientName"
        value={clientData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Surname"
        name="surname"
        value={clientData.surname}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Phone Number"
        name="phoneNumber"
        value={clientData.phoneNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={clientData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address"
        name="address"
        value={clientData.address}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleEditClient}
        sx={{
          mt: 2,
          color: "white",
          fontFamily: "Jua",
          fontWeight: 900,
          textAlign: "left",
        }}
      >
        Edit Client
      </Button>
    </Box>
  );
};

export default EditClient;

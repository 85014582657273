import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Grid,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../src/assets/Claws&PawsNoBack.png";
import "@fontsource/jua"; // Import Jua font
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./assets/Colours";

const theme = createTheme({
  typography: {
    fontFamily: "Jua, sans-serif",
  },
});

const Login = ({ setBusinessID }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://clawsandpawsapi.azurewebsites.net/api/Credentials/Login",
        {
          email,
          password,
        }
      );
      // Check if the status code is 200
      if (response.status === 200) {
        const businessID = "79497540-788d-4006-9f62-5c9319f9ba81";
        setBusinessID(businessID);
        localStorage.setItem("businessID", businessID);
        navigate("/dashboard");
      } else {
        setError("Invalid email or password");
      }
    } catch (error) {
      setError("Invalid email or password");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              background: PRIMARY_COLOR,
              padding: 4,
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(10px)",
            }}
          >
            <Box sx={{ flexShrink: 0, mr: 4 }}>
              <img
                src={logo}
                alt="Claws & Paws Logo"
                style={{ width: "350px", height: "auto" }}
              />
            </Box>
            <Box
              component="form"
              onSubmit={handleLogin}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 350,
              }}
            >
              <Typography variant="h4" component="h1" gutterBottom>
                Login
              </Typography>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              <TextField
                label="Email"
                variant="standard"
                margin="normal"
                fullWidth
                InputProps={{
                  disableUnderline: false,
                  style: { fontFamily: "Jua, sans-serif" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Jua, sans-serif" },
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                label="Password"
                variant="standard"
                type="password"
                margin="normal"
                fullWidth
                InputProps={{
                  disableUnderline: false,
                  style: { fontFamily: "Jua, sans-serif" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Jua, sans-serif" },
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  width: "60%",
                  padding: 1,
                  fontFamily: "Jua, sans-serif",
                  backgroundColor: SECONDARY_COLOR,
                }}
              >
                Login
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;

import React from "react";
import axios from "axios";
import { Button } from "@mui/material";

const DeleteClient = ({ clientID, onClientDeleted }) => {
  const handleDeleteClient = async () => {
    console.log(clientID);
    try {
      await axios.delete(
        `https://clawsandpawsapi.azurewebsites.net/api/Clients/${clientID}`
      );
      onClientDeleted();
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleDeleteClient}
      sx={{
        mt: 2,
        color: "white",
        fontFamily: "Jua",
        fontWeight: 900,
        textAlign: "left",
        backgroundColor: "#f44336",
      }}
    >
      Delete Client
    </Button>
  );
};

export default DeleteClient;

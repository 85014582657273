import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Hidden,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/People";
import LocalMallIcon from "@mui/icons-material/CalendarMonth";
import InventoryIcon from "@mui/icons-material/CalendarToday";
import LogoutIcon from "@mui/icons-material/Logout";
import BriefcaseIcon from "@mui/icons-material/Work";
import logo from "../src/assets/ClawsAndPawsLogo.png";
import { PRIMARY_COLOR } from "./assets/Colours";

const Sidebar = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("businessID");
    navigate("/login");
  };

  const drawerWidth = 290;

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
    { text: "Clients", icon: <ShoppingCartIcon />, path: "/clients" },
    { text: "Calendar", icon: <LocalMallIcon />, path: "/dashboard" },
    { text: "Appointments", icon: <InventoryIcon />, path: "/appointments" },
    { text: "Owner Tools", icon: <BriefcaseIcon /> },
    { text: "Financial Dashboard", icon: null, path: "/financial-dashboard" },
    { text: "Staff", icon: null, path: "/staff" },
  ];

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: PRIMARY_COLOR,
        color: "black",
        width: drawerWidth,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200,
          padding: "16px",
          overflow: "hidden",
        }}
      >
        <img
          src={logo}
          alt="ClawsAndPaws Logo"
          style={{
            height: "150px",
            objectFit: "contain",
          }}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={item.text}>
              <ListItem
                button
                onClick={() => {
                  if (item.text !== "Owner Tools") {
                    navigate(item.path);
                  }
                }}
                sx={{
                  ml: "15px",
                  "& span, & svg": {
                    fontFamily: "Jua",
                  },
                  "& span": {
                    fontSize: "21px",
                  },
                  ...(item.text !== "Owner Tools" && {
                    "&:hover": {
                      bgcolor: "white",
                      color: "#F1AC36",
                      borderRadius: "10px",
                      transition: "transform 0.3s",
                      transform: "translateY(-5px)",
                      "& .MuiListItemIcon-root": {
                        color: "#F1AC36",
                      },
                    },
                  }),
                  ...(item.text === "Owner Tools" && {
                    cursor: "default", // Change the cursor to default to indicate it's not clickable
                    "&:hover": {
                      bgcolor: "inherit", // No background change on hover
                      color: "inherit", // No color change on hover
                      transform: "none", // No transform effect
                    },
                  }),
                }}
              >
                {item.icon && (
                  <ListItemIcon sx={{ color: "#F1AC36" }}>
                    {React.cloneElement(item.icon, {
                      sx: { fontSize: "30px" },
                    })}
                  </ListItemIcon>
                )}
                <ListItemText primary={item.text} sx={{ fontSize: "18px" }} />
              </ListItem>

              {index < menuItems.length - 1}
            </React.Fragment>
          ))}
        </List>

        <List>
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              ml: "20px",
              "& span, & svg": {
                fontFamily: "Jua",
              },
              "& span": {
                fontSize: "21px",
              },
              "&:hover": {
                bgcolor: "white",
                color: "#F1AC36",
                transition: "transform 0.5s",
                transform: "translateY(-5px)",
                "& .MuiListItemIcon-root": {
                  color: "#F1AC36",
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: "#F1AC36" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Hidden mdDown>
        <Box
          sx={{
            width: drawerWidth,
            position: "fixed",
            height: "100vh", // Fixed height to viewport
            top: 0, // Positioning it at the top of the viewport
            left: 0, // Aligning to the left
          }}
        >
          {drawerContent}
        </Box>
        <Box
          sx={{
            width: drawerWidth, // Placeholder for the fixed sidebar
            flexShrink: 0,
          }}
        />
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          onClose={onClose}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              height: "100vh", // Ensures the drawer height matches the viewport height
              position: "fixed",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default Sidebar;
